<template>
  <div class="Login">
    <img src="../../assets/back_icon.png" @click="$router.go(-1)" class="back_icon" />
    <img v-if="navbar == 0" src="../../assets/login_logo_back.png" class="login_pwd_logo" />
    <img v-else src="../../assets/login_logo_back.png" class="login_pwd_logo" />
    <div class="title" v-if="navbar == 0">{{ $t('passwordLogin') }}</div>
    <div class="title" v-else>{{ $t('verificationCodeLogin') }}</div>

    <div class="content">
      <div class="item">
        <img src="../../assets/phone_icon.png" class="icon">
        <mu-text-field
          :label="$t('phone')"
          v-model="form.phone"
          type="text"
          label-float
          full-width
          color="#A194B4"
          :error-text="isCheck? validation(['phone']): ''"
          v-mask="'#### #### ##'"
        />
      </div>
      <div class="item" v-if="navbar == 0">
        <img src="../../assets/password_icon.png" class="icon">
        <mu-text-field
          :label="$t('enterPassword')"
          v-model="form.password"
          :type="eye_type"
          label-float
          full-width
          max-length="4"
          oninput = "value=value.replace(/[^\d]/g,'')"
          :error-text="isCheck? validation(['password']): ''"
          color="#A194B4">
          <img v-if="eye_type == 'password'" slot="append" src="../../assets/eye_icon.png" class="append_icon" @click="eye_type = 'test'">
          <img v-else slot="append" src="../../assets/eye_show_icon.png" class="append_icon" @click="eye_type = 'password'">
        </mu-text-field>
      </div>
      <div class="forget_password" @click="onPassword">{{ $t("passwordTips") }}</div>
      <div class="buttons">
        <mu-button full-width color="#E7AD5C" textColor="#192C4E" @click="submit">{{ $t("login") }}</mu-button>
        <div class="divider">
          {{ $t("or") }}
        </div>
        <mu-button
          full-width
          color="#18252A"
          textColor="#E7AD5C"
          style="border: 2px solid #E7AD5C;"
          @click="navbar = navbar? 0: 1"
        >
          {{ navbar ? $t("passwordLogin") : $t("verificationCodeLogin") }}
        </mu-button>
        <div class="tips">
          {{ $t("registerTips") }}
          <div class="sign_up" @click="onRegister">{{ $t("register") }}</div>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import { phoneRules, validateCodeRules, passwordRules } from "../../utils/rules";
import { mapState, mapMutations } from "vuex";
import { session } from '../../utils/api'
import { geolocation } from '../../utils/index'
import mixin from './mixin'
export default {
  name: "Login",
  mixins: [mixin],
  components: {},
  data() {
    return {
      eye_type: 'password',
      navbar: 0,
    };
  },
  computed: {
    ...mapState(["record"]),
    phoneState() {
      this.SET_RECORD_ITEM({
        key: "loginPhone",
        value: this.form.phone,
      });
      return this.isCheck ? phoneRules(this.form.phone) : null;
    },
    validateCodeState() {
      return this.isCheck ? validateCodeRules(this.form.validateCode) : null;
    },
    passwordState() {
      this.SET_RECORD_ITEM({
        key: "loginPassword",
        value: this.form.password,
      });
      return this.isCheck ? passwordRules(this.form.password) : null;
    },
  },
  created() {
    this.form.phone = this.record.loginPhone || "";
    this.form.password = this.record.loginPassword || "";
  },
  methods: {
    ...mapMutations(["SET_RECORD_ITEM", "SET_ACCESS_TOKEN"]),
    onPassword() {
      this.SET_RECORD_ITEM({
        key: "passwordPhone",
        value: this.form.phone,
      });
      this.$router.push('/user/password');
    },
    onRegister() {
      this.SET_RECORD_ITEM({
        key: "registerPhone",
        value: this.form.phone,
      });
      this.$router.push('/user/register');
    },
    async submit() {
      this.isCheck = true;
      var error;
      if (this.navbar == 0) {
        error = this.validation(['phone', 'password']);
        if (error) return this.$toast(error);
        this.$indicator.open({
          spinnerType: "fading-circle",
        });
        this.$NProgress.start();
        await this.$axios({
          method: "post",
          url: session,
          data: {
            loginType: 'PIN_LOGIN',
            phone: this.form.phone.replace(/\s/g, ""),
            pin: this.form.password,
            code: this.form.validateCode,
            deviceId: "H5",
            device: "H5",
            isValidateCode: 'Y',
            gps: await geolocation()
          },
        })
          .then(async (e) => {
            if (e.status.code == "000") {
              this.SET_ACCESS_TOKEN(e.body.accessToken);
              await this.getUser();
            } else {
              this.$toast(e.status.msg);
              if (e.status.code == "993") {
                this.SET_RECORD_ITEM({
                  key: "registerPhone",
                  value: this.form.phone,
                });
                this.$router.push('/user/register')
              }
            }
          })
          .catch(() => {});
        this.$indicator.close();
        this.$NProgress.done();
      } else {
        error = this.validation(['phone']);
        if (error) return this.$toast(error);
        this.SET_RECORD_ITEM({
          key: "codeType",
          value: "LOGIN",
        });
        this.SET_RECORD_ITEM({
          key: "codePhone",
          value: this.form.phone,
        });
        this.$router.push({path: '/user/code'});
      }
    },
  },
};
</script>

<style scoped lang="scss">
.Login {
  overflow: auto;
  background-color: #18252A;
  .back_icon {
    position: absolute;
    left: 16px;
    top: 16px;
    width: 20px;
  }
  .login_pwd_logo {
    height: 210px;
    margin: 50px auto 0px auto;
    display: block;
  }
  .title {
    text-align: left;
    margin-top: 20px;
    font-size: 20px;
    color: #E7AD5C;
    font-weight: bold;
    margin-left: 20px;
    margin-right: 20px;
  }
  .content {
    .item {
      display: flex;
      margin: 0px 20px 0px 20px;
      color: #F4F4F4;
      .icon {
        width: 20px;
        height: 20px;
        margin-right: 10px;
        margin-left: 5px;
        margin-top: 35px;
      }
      .append_icon {
        width: 20px;
        margin-right: 9px;
      }
      .mu-input {
        flex: 1;
      }
    }
    .forget_password {
      text-align: right;
      margin-right: 20px;
      margin-left: 20px;
      padding-right: 14px;
      font-size: 14px;
      color: #F4F4F4;
    }
    .buttons {
      margin-top: 15px;
      margin-left: 20px;
      margin-right: 20px;
    }
    .divider {
      display: flex;
      margin-left: 6px;
      margin-right: 6px;
      margin-bottom: 22px;
      align-items: center;
      font-size: 14px;
      color: #F4F4F4;
      &::before, &::after {
        content: "";
        width: 100%;
        height: 1px;
        background: #DDDDDD;
        margin: 0 8px;
      }
    }
    .tips {
      font-size: 19px;
      color: #F4F4F4;
      margin-bottom: 30px;
      text-align: center;
      .sign_up {
        display: inline-block;
        margin-left: 10px;
        font-weight: bold;
        color: #E7AD5C;
      }
    }
    /deep/ .mu-text-field-input {
      color: #F4F4F4;
      height: 38px;
    }
    /deep/ .mu-input-label {
      color: #F4F4F4;
    }
    /deep/ .mu-input-line {
      background-color: #F4F4F4;
    }
    /deep/ .mu-input.has-label {
      padding-top: 28px;
      padding-bottom: 4px;
      min-height: 70px;
    }
    /deep/ .mu-raised-button {
      border-radius: 8px;
      height: 50px;
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 30px;
    }
  }
}
</style>
